import { html, nothing } from "lit";
import { ref, createRef } from 'lit/directives/ref.js';
import { FullscreenMixin, PWAPage } from "../shared/pwa-page";
import { AuthenticationDomain } from "../domain/authentication-domain";
import { Session } from "../shared/session";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

export class PageLogin extends FullscreenMixin(PWAPage) {
  #domain;
  #waitingForCode;
  #unauthorized = false;

  #emailInputRef = createRef();
  #pinInputRef = createRef();

  constructor() {
    super();
    this.#domain = new AuthenticationDomain();
  }

  async checkOAuthHandling() {
    if (!this.#domain.isOAUthRedirect) {
      return;
    }

    const task = async () => {
      if (await this.#domain.handleOAuthRedirect()) {
        await app.session.refreshUser(true);

        if (!app.session.hasRole(Session.ROLE_QOGNI_ADMIN) && !app.session.hasRole(Session.ROLE_HR)) {
          await app.session.logout();
          this.#unauthorized = true;
          app.addToastMessage('You are not authorized to log in the backoffice', {type: 'error'});
        } else {
          location.replace("/?auth=1");
        }
      }
    };

    Task.run(task, {
      ghost: document.documentElement,
      description: "Handling OAuth callback",
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.checkOAuthHandling();
  }

  render() {
    if (app.session.isAuthenticated && (app.session.hasRole(Session.ROLE_QOGNI_ADMIN) || app.session.hasRole(Session.ROLE_HR))) {
      location.replace("/?auth=1");
    } else if (app.session.user) {
      app.session.logout();
    }

    return html`
      <div class="narrow">
        <div style="max-width: 500px; margin: 0 auto;">
          <small-image>
            <img src="/assets/img/qogni-logo.svg" alt="Qogni Logo"/>
          </small-image>

          ${this.#unauthorized ? html`
              <section class="card red">
                You are not authorized to access this environment.
              </section>
          ` : nothing}

          <section class="card">
            <x-form debug @action=${this.action}>
              <form method="get" action="?" class="material center">
                <fieldset>
                  <legend>
                    <h2>Sign in or sign up</h2>
                    <p>Get started for free</p>
                  </legend>

                  <button name="linkedin" type="button" class="wide outline blue" @click=${this.socialLogin}>
                    <svg-icon icon="linkedin" color="#2d64bc" size="24px"></svg-icon>
                    Continue with LinkedIn
                  </button>
                </fieldset>

                <hr data-content="or"/>

                <fieldset>
                  <input
                    name="email"
                    data-label="Work email"
                    type="email"
                    ?required=${!this.codeRequested}
                    ?readonly=${this.codeRequested}
                    ${ref(this.#emailInputRef)}
                    placeholder="Email address"
                  />
                </fieldset>

                <fieldset class=${!this.codeRequested ? "hidden" : "visible"}>
                  <input
                    name="pin"
                    data-label="Verification code"
                    type="number"
                    ?required=${this.codeRequested}
                    ${ref(this.#pinInputRef)}
                    placeholder="Paste sign up code"
                  />
                  <div class="info">
                    We sent a verification email to your inbox ●
                    <a href="#resend" class="primary" @click=${this.#resend}>Resend</a> ●
                    <a href="#reset" class="primary" @click=${this.#reset}>Different email address</a>
                  </div>
                </fieldset>

                <button name="email" class="green wide mb-tiny" type="submit">
                  Continue with email
                </button>

                <section class="disclaimer info">
                  By clicking "Continue with LinkedIn" or "Continue with email",
                  you agree to our User <a href="#tc">Terms of Service</a> and
                  <a href="#privacy">Privacy Policy</a>.
                </section>
              </form>
            </x-form>
          </section>
        </div>
      </div>
    `;
  }

  static get properties() {
    return {
      codeRequested: {type: Boolean},
    };
  }

  get codeRequested() {
    return this.#waitingForCode;
  }

  async socialLogin() {
    const task = async () => {
      try {
        await this.#domain.startWithLinkedIn();
      } catch {
        app.addToastMessage('We had an issue with requesting LinkedIn login method! Please try again later.', {
          type: 'error'
        });
      }
    }

    Task.run(task, {
      ghost: document.documentElement,
      description: "Request social authentication"
    });
  }

  /**
   * Handles x-form's action event.
   * @param {*} e
   */
  async action(e) {
    if (e.detail.name !== '--submit') return;
    e.preventDefault();

    const task = async () => {
      if (!e.detail.value.pin) {
        this.#waitingForCode = true;
        try {
          await this.#domain.startWithEmail(e.detail.value.email);
          this.#pinInputRef.value.focus();
        } catch {
          this.#waitingForCode = false;
          app.addToastMessage('We had some issues requesting your sign in. Please make sure you have internet connectivity and try again later', {
            type: 'error'
          });
        }
      } else {
        this.#waitingForCode = false;
        try {
          await this.#domain.activateUserSession(
            e.detail.value.email,
            e.detail.value.pin
          );

          await app.session.refreshUser();
          if (!app.session.hasRole(Session.ROLE_QOGNI_ADMIN) && !app.session.hasRole(Session.ROLE_HR)) {
            await app.session.logout();
            this.#unauthorized = true;
            app.addToastMessage('You are not authorized to access this application', {type: 'error'});
          } else {
            location.replace("/?auth=1");
          }
        } catch (err) {
          let message;
          if (err.response && err.response.status === 401) {
            message = 'The code you filled in is either not valid or has been expired. Please try again.';
          } else {
            message = 'We encountered an error trying to sign you in. Please try again in a bit.';
          }
          app.addToastMessage(message, {type: 'error'});
          this.#pinInputRef.value.value = '';
        }
      }
      this.requestUpdate();
    };
    Task.run(task, {
      ghost: e.target,
      description: "Sign in"
    });
  }

  async #resend() {
    const email = this.#emailInputRef.value.value;
    await this.#domain.startWithEmail(email);
    this.#pinInputRef.value.value = '';
    this.#pinInputRef.value.focus();
    app.addToastMessage('New code requested');
  }

  async #reset() {
    this.querySelector('form').reset();
    this.#waitingForCode = false;
    this.requestUpdate();
  }
}
