import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

export class PageQuestionEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  title = 'Question';

  #questionCategoryDomain;
  #questionnaireCategoryDomain;

  constructor() {
    super(new GenericDomain('/questions'));
    this.#questionCategoryDomain = new GenericDomain('/question_categories');
    this.#questionnaireCategoryDomain = new GenericDomain('/questionnaire_categories');
  }

  get fields() {
    return [
      {
        label: 'Question',
        property: 'question',
        readonly: false,
        type: String
      }, {
        label: 'Primary Category',
        property: 'question_category_id',
        required: true,
        type: 'Select',
        choices: async () => {
          const response = await this.#questionCategoryDomain.list({per_page: 1000});
          return response.data.map((c) => {
            return {value: c.id, name: c.name};
          });
        },
        help: 'The primary category that the scoring works against.'
      }, {
        label: 'Secondary Category',
        property: 'secondary_question_category_id',
        required: false,
        type: 'Select',
        choices: async () => {
          const response = await this.#questionCategoryDomain.list({per_page: 1000});
          return response.data.map((c) => {
            return {value: c.id, name: c.name};
          });
        },
        help: 'Secondary categories are used for internal coupling and deciding the category for Five Pillar calculation (and maybe more in the future)'
      }, {
        label: 'Description',
        property: 'description',
        required: false,
        type: String,
        expanded: true,
      }, new TagField({
        object: this.object,
        onChange: (e) => {
          if (! this.object) this.object = {tags: []};
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      })
    ];
  }
}
