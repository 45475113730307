import { AppDomainHandler } from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";

export class StatisticsDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async totalUsers(options) {
    return (await this.#api.getData('/statistics/users/total?'+(new URLSearchParams(options)))).data;
  }
  async activeUsers(options) {
    return (await this.#api.getData('/statistics/users/active?'+(new URLSearchParams(options)))).data;
  }
  async sales() {
    return (await this.#api.getData('/statistics/orders/sales')).data;
  }
  async budget(organisation_id) {
    return (await this.#api.getData(`/statistics/organisations/${organisation_id}/budget`)).data;
  }
}
