import { RoleMixin } from "../shared/pwa-page";
import { Session } from "../shared/session";
import { AdminPage } from "../shared/admin";

// const pieChartOptions = {
//   series: [44, 55, 13, 33],
//   labels: ['GABA', 'Dopamin', 'Serotonin', 'Acethylcholine'],
//   legend: {
//     show: false
//   },
//   chart: {
//     type: 'pie',
//   },
//   fill: {
//     colors: ['#E54271', '#2D9DC9', '#209666', '#F2A74D']
//   }
// };
//
// const pieChartJsonString = JSON.stringify(pieChartOptions);
//
// const lineChartOptions = {
//   series: [{
//     name: "Users",
//     data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
//   }],
//   chart: {
//     height: 350,
//     type: 'line',
//     zoom: {
//       enabled: false
//     }
//   },
//   colors: ['#2D9DC9'],
//   dataLabels: {
//     enabled: false
//   },
//   stroke: {
//     curve: 'straight'
//   },
//   grid: {
//     row: {
//       colors: ['#F4F0EA', 'transparent'], // takes an array which will be repeated on columns
//       opacity: 0.5
//     },
//   },
//   xaxis: {
//     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//   }
// };
//
// const lineChartJsonString = JSON.stringify(lineChartOptions);
//
// const areaChartOptions = {
//   series: [{
//     name: 'series1',
//     data: [31, 40, 28, 51, 42, 109, 100]
//   }, {
//     name: 'series2',
//     data: [11, 32, 45, 32, 34, 52, 41]
//   }],
//   chart: {
//     height: 350,
//     type: 'area'
//   },
//   dataLabels: {
//     enabled: false
//   },
//   legend: {
//     show: false
//   },
//   colors: ['#209666', '#F2A74D'],
//   stroke: {
//     curve: 'smooth'
//   },
//   xaxis: {
//     type: 'datetime',
//     categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
//   },
//   tooltip: {
//     x: {
//       format: 'dd/MM/yy HH:mm'
//     },
//   },
// };
//
// const areaChartJsonString = JSON.stringify(areaChartOptions);
//
// const columnChartOptions = {
//   series: [{
//     name: 'New users',
//     data: [77, 44, 55, 57, 14, 56, 61, 58, 63, 60, 66, 12]
//   }, {
//     name: 'Existing users',
//     data: [16, 13, 76, 85, 101, 98, 87, 105, 91, 114, 94, 12]
//   }, {
//     name: 'Potential increase',
//     data: [12, 35, 28, 41, 36, 26, 45, 48, 52, 53, 41, 12]
//   }],
//   chart: {
//     type: 'bar',
//     height: 400
//   },
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: '55%',
//       endingShape: 'rounded'
//     },
//   },
//   xaxis: {
//     categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev'],
//   },
//   yaxis: {
//     title: {
//       text: 'In thousands'
//     }
//   },
//   fill: {
//     colors: ['#E54271', '#2D9DC9', '#209666', '#F2A74D']
//   },
// };
//
// const columnChartJsonString = JSON.stringify(columnChartOptions);

export const links = () => {
  return [{
    name: 'Admin',
    link: '/dashboards/admin',
    role: Session.ROLE_QOGNI_ADMIN
  }, {
    name: 'HR',
    link: '/dashboards/hr',
    icon: 'account',
  }];
}

export class PageHome extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminPage, '/enter') {
  title = 'Dashboard';

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    if (app.session.hasRole(Session.ROLE_QOGNI_ADMIN)) {
      location.replace('/dashboards/admin');
    } else {
      location.replace('/dashboards/hr');
    }
  }
}

