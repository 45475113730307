import {css, html, LitElement, nothing} from "lit";

/**
 * Comparison statistics component
 */
customElements.define(
  "comparison-stat",
  class ComparisonStat extends LitElement {
    createRenderRoot() {
      return this;
    }

    static get properties() {
      return {
        score: {type: Number, attribute: true,},
        loading: {type: Boolean, attribute: true},
        oldScore: {type: Number, attribute: true,},
        label: {type: String, attribute: true,},
        icon: {type: String, attribute: true,},
        prefix: {type: String, attribute: true,},
        compareTime: {type: String, attribute: true},
      };
    }

    get percentageDifference() {
      const percentage = (100 * Math.abs((this.score - this.oldScore) / ((this.score + this.oldScore) / 2))).toFixed(1);
      if (isNaN(percentage)) return 0;
      return percentage;
    }

    get scoreStatus() {
      if (this.score < this.oldScore) {
        return 'negative';
      }
      return 'positive';
    }

    render() {
      return html`
        <div class="${this.scoreStatus}">
          <flex-container class="justify-content-space-between">
            <h3>${this.label}</h3>
            <div class="icon">
              <svg-icon size="24px" icon="${this.icon}"></svg-icon>
            </div>
          </flex-container>

          <div class="score">
            ${this.loading ? html`
              <app-shimmer class="tiny"></app-shimmer>
            ` : html`
              ${this.prefix} ${this.score}
            `}
          </div>

          ${! this.loading && this.oldScore !== undefined ? html`
            <flex-container class="comparison">
              <flex-item>
                <div class="change">
                  <svg-icon size="14px" icon="arrow"></svg-icon>
                  ${this.percentageDifference}%
                </div>
              </flex-item>

              <flex-item>
                <span class="label" title="${this.oldValue}">
                  compared to last ${this.compareTime ?? 'month'}
                </span>
              </flex-item>
            </flex-container>
            ` : nothing}
        </div>
      `
    }
  }
);
