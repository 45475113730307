import { PWAPage, RoleMixin } from "../../shared/pwa-page";
import { StatisticsDomain } from "../../domain/statistics-domain";
import { UserDomain } from "../../domain/user-domain";
import { links } from "../home";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { AdminIndexPage, AdminPage } from "../../shared/admin";
import { Session } from "../../shared/session";

export class PageDashboardHR extends RoleMixin([Session.ROLE_HR, Session.ROLE_QOGNI_ADMIN], AdminPage, '/') {
  title = 'HR Dashboard';

  #statDomain;
  #userDomain;
  #budgetInterval;

  constructor() {
    super();

    this.#statDomain = new StatisticsDomain();
    this.#userDomain = new UserDomain();
    this.topLinks = links();
    this.budgetIndex = 0;
  }

  static get properties() {
    return {
      organisationId: { type: String },
      totalUsers: { type: Number },
      newUsersThisWeek: { type: Number },
      newUsersLastWeek: { type: Number },
      activeUsers: { type: Number },
      budget: { type: Array },
      budgetIndex: { type: Number },
    }
  }

  connectedCallback() {
    this.organisationId = app.session.user.organisation_id;
    super.connectedCallback();
    this.fetch();

    this.#budgetInterval = setInterval(() => {
      if (this.budget !== undefined) {
        this.budgetIndex = (this.budgetIndex + 1) % this.budget.length;
      }
    }, 3000);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    clearInterval(this.#budgetInterval);
  }

  async fetch() {
    this.#fetchTotalUsers();
    this.#fetchActiveUsers();
    this.#fetchBudget();
  }

  #fetchTotalUsers() {
    Task.run(async() => {
      const response = await this.#statDomain.totalUsers();
      this.totalUsers = response.total_users;
      this.newUsersLastWeek = response.created_last_week;
      this.newUsersThisWeek = response.created_this_week;
    }, {global: false});
  }

  #fetchActiveUsers() {
    Task.run(async() => {
      const response = await this.#statDomain.activeUsers();
      this.activeUsers = response.active_users;
    }, {global: false});
  }

  #fetchBudget() {
    if (! this.organisationId) return;
    Task.run(async() => {
      this.budget = await this.#statDomain.budget(this.organisationId);
    }, {global: false});
  }

  render() {
    return html`
      ${this.topLinks ? html`
        ${AdminIndexPage.renderBreadcrumbs(this.topLinks, this.title, 'Overview of the users of the system.')}
      ` : nothing}

      ${this.#renderStats()}
    `;
  }

  #renderStats() {
    return html`
      ${! this.organisationId ? html`
        <section class="card col-12">
          <strong>Your account is not associated with an organisation.</strong>
        </section>
      ` : html`
        <section class="card col-3">
          <comparison-stat
            score="${this.budget[this.budgetIndex]?.balance}"
            ?loading="${this.budget === undefined}"
            label="Budget"
            icon="metrics"
            prefix="${this.budget[this.budgetIndex]?.currency?.symbol ?? ''}"
            color="yellow"
          ></comparison-stat>
        </section>

        <section class="card col-3">
          <comparison-stat
            score="${this.activeUsers}"
            ?loading="${this.activeUsers === undefined}"
            label="Active users (30 days)"
            icon="people"
            prefix=""
            color="blue"
          ></comparison-stat>
        </section>

        <section class="card col-3">
          <comparison-stat
            score="${this.newUsersThisWeek}"
            oldScore="${this.newUsersLastWeek}"
            ?loading="${this.newUsersThisWeek === undefined}"
            compareTime="week"
            label="New users this week"
            icon="people"
            prefix=""
            color="blue"
          ></comparison-stat>
        </section>

        <section class="card col-3">
          <comparison-stat
            score="${this.totalUsers}"
            ?loading="${this.totalUsers === undefined}"
            label="Total users"
            icon="people"
            prefix=""
            color="red"
          ></comparison-stat>
        </section>
      `}
    `
  }
}
